import React from "react";
import PropTypes from "prop-types";
import toastr from "toastr";

const $ = window.$;
class ApikeyComponent extends React.Component {

	componentDidMount() {
    this.onActivateShortcut();
  }

	onCopyApikey = () => {
    const { user } = this.props;
    navigator.clipboard.writeText(user.apikey);
    toastr.success("Apikey copied");
  }

	onActivateShortcut = () => {
    $(window).on("keydown", e => {
      if (e.ctrlKey) {
        if(e.altKey){
          if(e.key.toLowerCase() === "c"){
            e.preventDefault();
            e.stopPropagation();
            this.onCopyApikey()
          }
        }
      }
    });
  }

	render() {
		return (			
      <li className="ns-link" onClick={this.onCopyApikey}>Copy API key</li>
		)
	}
}

ApikeyComponent.propTypes={
	user: PropTypes.object.isRequired
}

export default ApikeyComponent;