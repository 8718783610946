import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tag } from "antd";

import { ENABLE_PAYMENT } from "../../../../utils/ui";
import { applicationRoutes } from "../../../../routes/common";
import { DASHBOARD_STEP_UPGRADE_PLAN_TARGET } from "../../../../constants/tour-steps/dashboard-tour-steps";
import * as AuthState from "../../../../redux/states/auth-state";
import AuthModule from "../../../../redux/modules/auth-module";

const getClassName = (credit, current) =>{
  if(credit < current){
    return "red";
  }else{
    return "green";
  }
}

class CreditUsage extends React.Component {

  onNavigateUpgrade = () => {
    this.props.history.push(applicationRoutes.SUBSCRIPTION_BILLING_ROUTE);
  }

  render() {
    const {user, credit, currentUsage } = this.props;
    return (
      <div>
        {ENABLE_PAYMENT === "YES" && user && user.accountType === "paid" && (
          <div className={`${getClassName(credit, currentUsage)} ${DASHBOARD_STEP_UPGRADE_PLAN_TARGET}`}>
            <Tag className="ns-p-1 ns-pointer" onClick={this.onNavigateUpgrade}  color={getClassName(credit,currentUsage)}>
              Available Credit: <span className="ns-font-weight-bold">${credit}</span> | Current Usage: <span className="ns-font-weight-bold">${currentUsage}</span>
            </Tag>
          </div>
        )}
      </div>
    )
  }
}

CreditUsage.propTypes = {
  user: PropTypes.object.isRequired,
  credit: PropTypes.number.isRequired
}

const CreditUsageContainer = connect(
  state => ({
    user: state.get(AuthState.reducerName).user,
    credit: state.get(AuthState.reducerName).credit,
    currentUsage: state.get(AuthState.reducerName).currentUsage
  }),
  dispatch => ({
    authReducer: AuthModule.getActions(dispatch)
  })
)(CreditUsage);

export default CreditUsageContainer