export const BRAND_NAME = "NeuralSpace";
const DESC = `${BRAND_NAME} - The modular NLP Platform for Asian, Middle Eastern and African Languages`

export const SIGN_UP_HELMET={
  title: `${BRAND_NAME} - Sign up`,
  description: DESC
};
export const LOGIN_HELMET={
  title: `${BRAND_NAME} - Login`,
  description: DESC
};
export const VERIFY_EMAIL_HELMET={
  title: `${BRAND_NAME} - Verify email`,
  description: DESC
};
export const CONFIRM_EMAIL_HELMET={
  title: `${BRAND_NAME} - Confirm email`,
  description: DESC
};
export const FORGOT_PASSWORD_HELMET={
  title: `${BRAND_NAME} - Forgot password`,
  description: DESC
};
export const RESET_PASSWORD_HELMET={
  title: `${BRAND_NAME} - Reset password`,
  description: DESC
};

export const MAIN_DASHBOARD_HELMET={
  title: `${BRAND_NAME} - Dashboard`,
  description: DESC
};
export const NS_APPS_HELMET={
  title: `${BRAND_NAME} - Services`,
  description: DESC
};

//NLU
export const NLU_DASHBOARD_HELMET={
  title: `${BRAND_NAME} - Language Understanding Dashboard`,
  description: DESC
};
export const NLU_PROJECTS_HELMET={
  title: `${BRAND_NAME} - Language Understanding Projects`,
  description: DESC
};
export const NLU_ENTITIES_HELMET={
  title: `${BRAND_NAME} - Language Understanding Entities`,
  description: DESC
};
export const NLU_MODEL_FEEDBACK_HELMET={
  title: `${BRAND_NAME} - Language Understanding Model feedback`,
  description: DESC
};
export const NLU_DATA_STUDIO={
  title: `${BRAND_NAME} - Language Understanding Data Studio`,
  description: DESC
};
export const NLU_DATASETS_HELMET={
  title: `${BRAND_NAME} - Language Understanding Datasets`,
  description: DESC
};

//NER
export const NER_DASHBOARD_HELMET={
  title: `${BRAND_NAME} - Entity Recognition Dashboard`,
  description: DESC
};
export const NER_PROJECTS_HELMET={
  title: `${BRAND_NAME} - Entity Recognition Projects`,
  description: DESC
};
export const NER_ENTITIES_HELMET={
  title: `${BRAND_NAME} - Entity Recognition Entities`,
  description: DESC
};
export const NER_MODEL_FEEDBACK_HELMET={
  title: `${BRAND_NAME} - Entity Recognition Model feedback`,
  description: DESC
};
export const NER_DATA_STUDIO={
  title: `${BRAND_NAME} - Entity Recognition Data Studio`,
  description: DESC
};
export const NER_DATASETS_HELMET={
  title: `${BRAND_NAME} - Entity Recognition Datasets`,
  description: DESC
};

//Transliteration
export const TRANSLITERATION_DASHBOARD_HELMET={
  title: `${BRAND_NAME} - Transliteration Dashboard`,
  description: DESC
};
export const TRANSLITERATION_PROJECTS_HELMET={
  title: `${BRAND_NAME} - Transliteration Projects`,
  description: DESC
};
export const TRANSLITERATION_MODEL_FEEDBACK_HELMET={
  title: `${BRAND_NAME} - Transliteration Model feedback`,
  description: DESC
};
export const TRANSLITERATION_DATA_STUDIO={
  title: `${BRAND_NAME} - Transliteration Data Studio`,
  description: DESC
};
export const TRANSLITERATION_DATASETS_HELMET={
  title: `${BRAND_NAME} - Transliteration Datasets`,
  description: DESC
};


//Transliteration
export const STT_DASHBOARD_HELMET={
  title: `${BRAND_NAME} - Transcription Dashboard`,
  description: DESC
};
export const STT_FILE_TRANSCRIPTION_HELMET={
  title: `${BRAND_NAME} - File Transcription`,
  description: DESC
};
export const STT_DICTATION_HELMET={
  title: `${BRAND_NAME} - Transcription Dictation`,
  description: DESC
};

