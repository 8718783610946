import { PlusOutlined, CloseOutlined, SyncOutlined } from '@ant-design/icons';
import _ from "lodash";

import { escapeRegex, getTextData } from "../common-helper";
import { rtlLanguages, reverseLangMap } from "../../utils/ui";
import { defaultTrainingJob } from "../../utils/apps/ner-utils";
import { FAILED_TRAINING } from './ner-model-helper';

const getColors = {
  1: {
    outer: "color: rgb(0 0 0 / 81%); background: #fff0f6; border-color: #ffadd2;",
    inner: "background: #ffadd2;",
    bg: "#fff0f6"
  },
  2: {
    outer: "color: rgb(0 0 0 / 81%); background: #fff1f0; border-color: #ffa39e;",
    inner: "background: #ffa39e;",
    bg: "#fff1f0"
  },
  3: {
    outer: "color: rgb(0 0 0 / 81%); background: #fff2e8; border-color: #ffbb96;",
    inner: "background: #ffbb96;",
    bg: "#fff2e8"
  },
  4: {
    outer: "color: rgb(0 0 0 / 81%); background: #fff7e6; border-color: #ffd591;",
    inner: "background: #ffd591;",
    bg: "#fff7e6"
  },
  5: {
    outer: "color: rgb(0 0 0 / 81%); background: #fffbe6; border-color: #ffe58f;",
    inner: "background: #ffe58f;",
    bg: "#fffbe6"
  },
  6: {
    outer: "color: rgb(0 0 0 / 81%); background: #fcffe6; border-color: #eaff8f;",
    inner: "background: #eaff8f;",
    bg: "#fcffe6"
  },
  7: {
    outer: "color: rgb(0 0 0 / 81%); background: #f6ffed; border-color: #b7eb8f;",
    inner: "background: #eaff8f;",
    bg: "#f6ffed"
  },
  8: {
    outer: "color: rgb(0 0 0 / 81%); background: #e6fffb; border-color: #87e8de;",
    inner: "background: #87e8de;",
    bg: "#e6fffb"
  },
  9: {
    outer: "color: rgb(0 0 0 / 81%); background: #e6f7ff; border-color: #91d5ff;",
    inner: "background: #91d5ff;",
    bg: "#e6f7ff"
  },
  0: {
    outer: "color: #000; background: #f9f0ff; border-color: #d3adf7;",
    inner: "background: #d3adf7;",
    bg: "#f9f0ff"
  },
  10: {
    outer: "color: #1d39c4; background: #f0f5ff; border-color: #adc6ff;",
    inner: "background: #adc6ff;",
    bg: "#f0f5ff"
  }
}

export const getProjectLanguage = (project) => {
  let text = "";
  if (project && project.language && project.language.length > 0) {
    const lang = reverseLangMap[project.language[0]];
    if (lang) text = lang;
  }
  return text;
}

export const getExampleType = example => {
  let data = { value: "", color: "" }
  if (example.type === "train") {
    data.value = "Train";
    data.color = "#008000ad";
  } else {
    data.value = "Test";
    data.color = "#ff000080";
  }
  return data;
}

export const getExamplePreparedFlag = example => {
  let data = { value: "", color: "" }
  if (example.prepared) {
    data.value = "Prepared";
    data.color = "#008000ad";
  } else {
    data.value = "Not prepared";
    data.color = "#ff000080";
  }
  return data;
}


export const getDisableTrainBtn = (dist, project) => {
  let flag = true;
  const { trainExamples } = project;
  if(trainExamples >= 10){
    // if(dist && dist.length >= 2){
    //   if(dist.some(d=>d.train >= 2)){
    //     flag = false;
    //   }
    // }
    flag = false;
  }
  return flag;
}


export const showExampleActionbtn = (example, oneExample) => {
  let flag = false;
  if (oneExample && oneExample.exampleId && example && example.exampleId) {
    if (oneExample.exampleId === example.exampleId) {
      flag = true;
    }
  }
  return flag;
}

const updateEntities = entities => {
  let uniqueEntities = _.uniqBy(entities, "start");
  uniqueEntities = _.uniqBy(uniqueEntities, "end");
  uniqueEntities = uniqueEntities.sort((a,b)=>a.start - b.start);
  let prevEnd;
  for(let i=0;i<uniqueEntities.length;i++){
    uniqueEntities[i].modifiedStart = uniqueEntities[i].start;
    uniqueEntities[i].modifiedEnd = uniqueEntities[i].end;
    if(!i){
      prevEnd = uniqueEntities[i].modifiedEnd;
      continue;
    }
    if(uniqueEntities[i].modifiedStart < prevEnd && uniqueEntities[i].modifiedEnd > prevEnd){
      let diff = prevEnd + 1 - uniqueEntities[i].modifiedStart;
      uniqueEntities[i].modifiedStart += diff;
      uniqueEntities[i].modifiedEnd += diff;
      prevEnd = uniqueEntities[i].modifiedEnd;
    }else if(uniqueEntities[i].modifiedStart > prevEnd){
      continue;
    }else{
      uniqueEntities.splice(i--, 1);
    }
  }
  return uniqueEntities;
}

export const getConvertExampleData1 = (exampleData) => {
  const { entities, text } = exampleData;
  if (!entities || (entities && entities.length === 0) || !text) return text;
  let styledExample = text;
  let deepEntities = updateEntities(_.cloneDeep(entities));
  deepEntities.sort((a, b) => b.start - a.start);
  deepEntities.forEach(r => {
    const { start, end, entity, value, modifiedEnd, modifiedStart } = r;
    const color = getColors[10];
    styledExample = styledExample.substring(0, modifiedStart) +
      `<span contenteditable="false" data-action="${value}---${start}---${end}---${color.bg}---${entity}" unselectable="on" onselectstart="return false;" name="entity" class="ne-c" style="${color.outer}">${value}<span class="ne-c-inner" unselectable="on" onselectstart="return false;" data-action="${value}---${start}---${end}---${color.bg}---${entity}" style="${color.inner}">${entity}</span></span>`
      + styledExample.substring(modifiedEnd);
  });
  return styledExample;
}

export const getConvertExampleData = exampleData => {
  let {entities, text} = exampleData;
  const deepEntities = _.cloneDeep(entities);
  deepEntities.sort((a,b)=> b.start - a.start);
  let newEntities = [];
  deepEntities.map(d=> newEntities.filter(a=> a.start === d.start && a.end === d.end).length > 0 ? null : newEntities.push(d));
  let inserted = [];
  newEntities.forEach(r=>{
    const { start, end, entity, value } = r;
    let modifiedStart = start, modifiedEnd = end;
    const color = getColors[10];
    for(let i in inserted){
      if(i < start){
        modifiedStart += inserted[i];
      }
      if(i<=end){
        modifiedEnd += inserted[i];
      }
    }
    let preText = `<span contenteditable="false" data-action="${value}---${start}---${end}---${color.bg}---${entity}" unselectable="on" onselectstart="return false;" name="entity" class="ne-c" style="${color.outer}">`;
    let postText = `<span class="ne-c-inner" unselectable="on" onselectstart="return false;" data-action="${value}---${start}---${end}---${color.bg}---${entity}" style="${color.inner}">${entity}</span></span>`;

    inserted[start] = (inserted[start] || 0) + preText.length;
    inserted[end] = (inserted[end] || 0) + postText.length;

    text = text.substring(0, modifiedStart) +
          preText +
          text.substring(modifiedStart, modifiedEnd) +
          postText +
          text.substring(modifiedEnd);
  });
  return text;
}

export const enableSaveBtn = example => {
  if (!example) return true;
  const text = getTextData(example.exampleId);
  return text && example.text === text;
}


export const searchSynonyms = (search, arr) => {
  search = search ? escapeRegex(search) : ".";
  let searchRegex = new RegExp(search, "i");
  const stringData = arr.filter(a => searchRegex.test(a.entity));
  return stringData.slice(0, 10);
}

export const showSynonymTagdata = (list, synonym, selected) => {
  let data = {
    color: "geekblue",
    action: "add",
    classNames: "ns-tag-margins ns-pointer",
    icon: <PlusOutlined />
  };
  const filterSynonym = list.filter(l => l.entityId === synonym.entityId)[0];
  if (selected && selected.entityId === synonym.entityId) {
    data = {
      color: "",
      action: "",
      classNames: "ns-tag-margins ns-no-pointer",
      icon: <SyncOutlined spin />
    }
  } else if (filterSynonym) {
    data = {
      color: "volcano",
      action: "remove",
      classNames: "ns-tag-margins ns-pointer",
      icon: <CloseOutlined />
    }
  }
  return data;
}

export const createEntityConfidenceRange = examples => {
  let data = [];
  examples.forEach(ex => {
    ex.entities.forEach(en => {
      data.push(customToFixed(en.confidence, 1))
    });
  });
  data.sort((a, b) => a - b);
  return data;
}
// const gcd = (a,b) => a===b ? b : gcd(b%a, a);

export const createRangeSteps = arr => {
  let step = 1;
  // if(validArray(arr)){
  //   for(let i=0;i<arr.length;i++){
  //     step = gcd(arr[i], step);
  //     if(step === 1) {
  //       return step;
  //     }
  //   }
  // }
  return step;
}

export const customToFixed = (num, fixed) => {
  fixed = fixed || 0;
  fixed = Math.pow(10, fixed);
  return Math.floor(num * fixed) / fixed;
}

export const createNewExample = (example) => {
  const { projectId, language, entities, exampleId, type } = example
  let exampleObj = {
    projectId,
    language,
    exampleId,
    example: {
      type,
      text: getTextData(exampleId),
      entities: entities.map(en => delete en.confidence && en)
    }
  }
  return exampleObj;
}

export const rtlFlag = language => language && rtlLanguages.includes(language) ? "rtl" : "ltr";

export const getTrainingJobValue = project => (project && project.noOfTrainingJob) || defaultTrainingJob;

export const removeConfidence = (entities) => {
  if (entities && entities.length > 0) {
    entities.forEach(en => {
      delete en.confidence;
    })
  }
  return entities;
}

export const getProjectsQueryParams = search => {
  let params = {
    languages: [],
    pageNumber: "1",
    pageSize: "12"
  }
  const query = new URLSearchParams(search);
  if (query.get("languages")) params.languages = query.get("languages").split(",");
  if (query.get("page")) params.pageNumber = parseInt(query.get("page"));
  if (query.get("limit")) params.pageSize = parseInt(query.get("limit"));

  return params;
}

export const getProjectDetailsQueryParams = search => {
  let params = {
    trainingStatus: [],
    deployedStatus: "All",
    pageNumber: "1",
    pageSize: "12",
    modelId: ""
  }
  const query = new URLSearchParams(search);
  if (query.get("trainingStatus")) params.trainingStatus = query.get("trainingStatus").split(",");
  if (query.get("deployedStatus")) params.deployedStatus = query.get("deployedStatus");
  if (query.get("page")) params.pageNumber = parseInt(query.get("page"));
  if (query.get("limit")) params.pageSize = parseInt(query.get("limit"));
  if (query.get("model")) params.modelId = query.get("model");
  return params;
}

export const getDataStudioParams = search => {
  let params = {
    exampleStatus: "all",
    exampleType: "all",
    entityType: "all",
    entity: "All",
    pageNumber: "1",
    pageSize: "15"
  }
  const query = new URLSearchParams(search);
  if (query.get("status")) params.exampleStatus = query.get("status");
  if (query.get("type")) params.exampleType = query.get("type");
  if (query.get("entityType")) params.entityType = query.get("entityType");
  if (query.get("entity")) params.entity = query.get("entity");
  if (query.get("page")) params.pageNumber = parseInt(query.get("page"));
  if (query.get("limit")) params.pageSize = parseInt(query.get("limit"));
  return params;
}


export const getNsImportDatasetQueryParams = search => {
  let params = {
    language: "All",
    planType: "",
    search: "",
    domain: [],
    entity: "All",
    entityRange: [],
    pageNumber: "1",
    pageSize: "12",
    importPage: "1"
  }
  const query = new URLSearchParams(search);
  if (query.get("language")) params.language = query.get("language");
  if (query.get("plan")) params.planType = query.get("plan");
  if (query.get("search")) params.search = query.get("search");
  if (query.get("domain")) params.domain = query.get("domain").split(",");
  if (query.get("entity")) params.entity = query.get("entity");
  if (query.get("entityRange")) params.entityRange = query.get("entityRange").split("-");
  if (query.get("page")) params.pageNumber = parseInt(query.get("page"));
  if (query.get("limit")) params.pageSize = parseInt(query.get("limit"));
  if (query.get("importPage")) params.importPage = parseInt(query.get("importPage"))
  return params;
}

export const getNerUploadDatasetQueryParams = search => {
  let params = {
    pageNumber: "1"
  }
  const query = new URLSearchParams(search);
  if (query.get("page")) params.pageNumber = parseInt(query.get("page"));
  return params;
}

export const checkUploadFailed = upload => {
  let data = false;
  if (upload) {
    if (upload.status === FAILED_TRAINING || upload.failedExamples > 0) {
      data = true
    }
    if (upload.entityStatus) {
      for (let i = 0; i < upload.entityStatus.length; i++) {
        if (upload.entityStatus[i].failed > 0) {
          data = true
          break;
        }
      }
    }
  }
  return data;
}