import React from "react";
import toastr from "toastr";
import { Button } from "antd";

import { CopyOutlined, FileWordOutlined } from "@ant-design/icons";
import { getAccessToken } from "../../helpers/common-helper";
import { DOCS_URL } from "../../constants/common-constant";

const $ = window.$;
class AccessTokenComponent extends React.Component {

	componentDidMount() {
    this.onActivateShortcut();
  }

	onCopyToken = () => {
    const getToken = getAccessToken();
    navigator.clipboard.writeText(getToken);
    toastr.success("Auth Token copied");
  }

  onNavigateDocs = () => {
    window.open(DOCS_URL, "_blank");
  };

	onActivateShortcut = () => {
    $(window).on("keydown", e => {
      if (e.ctrlKey) {
        if(e.altKey){
          if(e.key.toLowerCase() === "a"){
            e.preventDefault();
            e.stopPropagation();
            this.onCopyToken()
          }
        }
      }
    });
  }

	render() {
		return (
			<li className="ns-mr-3">
        <Button type="primary" ghost size="small" onClick={this.onNavigateDocs} className="ns-mr-2" icon={<FileWordOutlined />}>Docs</Button>
				<Button type="primary" ghost size="small" onClick={this.onCopyToken} icon={<CopyOutlined />}>Copy Auth Token</Button>
			</li>
		)
	}
}

export default AccessTokenComponent;