import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Layout } from "antd";
import { Link } from "react-router-dom";

import NSBlackIcon from "../../../../assets/images/icon.svg";
import NSWhiteIcon from "../../../../assets/images/w_icon.svg";
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE, THEME_TYPE_DARK } from "../../../../constants/theme-setting-constants";
import * as SettingsState from "../../../../redux/states/settings-state";
import * as AuthState from "../../../../redux/states/auth-state";
import SettingsReducer from "../../../../redux/modules/settings-module";
import UserInfo from "../../../../components/user-info/user-info";
import AccessTokenComponent from "../../../../components/common/access-token-component";
import { DASHBOARD_STEP_HEADER_MENU_TARGET } from "../../../../constants/tour-steps/dashboard-tour-steps";
import CreditUsageContainer from "../../common/subscription/credit-usage";

class HeaderBar extends React.Component {

  onNavCollapsed = () => {
    const { navCollapsed, settingsReducer } = this.props;
    settingsReducer[SettingsState.SET_NAVCOLLAPSED.name](!navCollapsed);
  }

  render() {
    const { windowWidth, themeType, navStyle, user } = this.props;
    return (
      <Layout.Header>
        {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && windowWidth < TAB_SIZE) ?
          <div className="ns-linebar ns-mr-3">
            <i className="ns-icon-btn icon icon-menu"
              onClick={this.onNavCollapsed}
            />
          </div> : null}
        <Link to="/" className="ns-d-block ns-d-lg-none ns-pointer">
          <img className="ns-nav-avatar-style" alt="" src={themeType === THEME_TYPE_DARK ? NSWhiteIcon : NSBlackIcon} />
        </Link>
        <CreditUsageContainer {...this.props}/>
        <ul className={`ns-header-notifications ns-ml-auto ${DASHBOARD_STEP_HEADER_MENU_TARGET}`}>
          <AccessTokenComponent />
          <li className="ns-user-nav">
            <UserInfo user={user} />
          </li>
        </ul>
      </Layout.Header>
    )
  }
}

HeaderBar.propTypes = {
  navStyle: PropTypes.string.isRequired,
  themeType: PropTypes.string.isRequired,
  windowWidth: PropTypes.number.isRequired,
  locale: PropTypes.object.isRequired,
  navCollapsed: PropTypes.bool.isRequired,
  settingsReducer: PropTypes.object.isRequired
}

const HeaderBarContainer = connect(
  state => ({
    user: state.get(AuthState.reducerName).user,
    credit: state.get(AuthState.reducerName).credit,
    navStyle: state.get(SettingsState.reducerName).navStyle,
    themeType: state.get(SettingsState.reducerName).themeType,
    windowWidth: state.get(SettingsState.reducerName).windowWidth,
    locale: state.get(SettingsState.reducerName).locale,
    navCollapsed: state.get(SettingsState.reducerName).navCollapsed
  }),
  dispatch => ({
    settingsReducer: SettingsReducer.getActions(dispatch)
  })
)(HeaderBar);

export default HeaderBarContainer;